import { Button, Card, Col, Form, Modal, Row, Table, ToastContainer } from "react-bootstrap";
import { AccountContext } from "../../../Context/AccountContext/AccountContext";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ModalCravador } from "./Cravador/ModalCravador";
import { ScreenContext } from "../../../Context/Screen/context";
import { Search } from "lucide-react";
import { NailingContext } from "../../../Context/NailingContext/Context";
import { swalErro, toastErro } from "../../../components/Swal";

export default function ControleCravacao() {
	const { list, accountList, addNailingValue } = useContext(AccountContext);
	const { getOrderNailingUser } = useContext(NailingContext);

	useEffect(function () {
		accountList([17, 18]);
	}, []);

	const [modalAlterarShow, setModalAlterarShow] = useState(false);
	const [modalAlterarData, setModalAlterarData] = useState([]);
	const [nailingValue, setNailingValue] = useState([]);

	const handleOpenModalAlterarValoresCravadores = data => {
		setModalAlterarShow(true);
		setModalAlterarData(data);
		setNailingValue(data?.nailing_value);
	};

	const changeNailingValue = (nailing, event) => {
		const index = nailingValue.findIndex(n => n.id_nailing_value === nailing);
		if (index !== -1) {
			const newArray = [...nailingValue];
			newArray[index] = {
				...newArray[index],
				nailing_value: event?.replace(",", "."),
			};
			setNailingValue(newArray);
		}
	};

	const handleSubmitAlteraValoresCravadores = async e => {
		e.preventDefault();
		toast.loading("Salvando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});

		const payload = {
			user: modalAlterarData.id_user,
			nailing: nailingValue,
		};

		const response = await addNailingValue(payload);

		if (response.data.status == "success") {
			toast.dismiss();
			toast.success(response.data.message, {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 2000,
			});
			accountList([17]);
			setModalAlterarShow(false);
		} else {
			toast.dismiss();
			toast.error(response.data.message, {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 2000,
			});
		}
	};

	const { screenWidth, handleResize } = useContext(ScreenContext);
	const [openModalNailingUser, setOpenModalNailingUser] = useState(false);
	const [listaNailingUser, setListaNailingUser] = useState([]);
	const handleCloseModalNailingUser = () => {
		setOpenModalNailingUser(false);
	};
	useEffect(() => {
		handleResize();
	}, []);

	const handleGetNailingUSer = async event => {
		event.preventDefault();
		let order_code = event.target.pesquisar.value;

		const response = await getOrderNailingUser({ order_code: order_code });
		if (response.data.lista?.length > 0) {
			setOpenModalNailingUser(true);
			setListaNailingUser(response.data.lista);
		} else {
			swalErro("Nenhum registro de cravação encontrado para este pedido!");
		}
	};

	

	return (
		<div>
			<ToastContainer />
			<div className="d-flex flex-column gap-3">
				<Card className="card-one">
					<Card.Header>
						<Card.Title>Pesquisar</Card.Title>
					</Card.Header>
					<Card.Body>
						<div className={`border form-search mb-3 ${screenWidth < 1100 ? "w-100" : "w-20"}`}>
							<Form className="d-flex w-100" onSubmit={handleGetNailingUSer}>
								<Form.Control className="w-100" placeholder="Pesquisar" id="pesquisar" name="pesquisar"></Form.Control>
								<Button type="submit" variant="outline">
									<Search />
								</Button>
							</Form>
						</div>
					</Card.Body>
				</Card>
				{list.some(u => u.permission_type == 17) ? (
					<Card className="card-one">
						<Card.Header>
							<Card.Title>Cravadores Internos</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex flex-column gap-3">
							{list?.map((u, i) => {
								if (u.permission_type == 17) {
									return (
										<div className="" key={i}>
											<div className="d-flex justify-content-between">
												<Card className="d-flex w-100 flex-row justify-content-between align-items-center">
													<Card.Body className="d-flex w-100 flex-row justify-content-between align-items-center">
														<Card.Title className="px-2 py-0 m-0 d-flex align-items-center justify-content-center">
															<ModalCravador setter={u} />
														</Card.Title>

														<div className="w-60 d-flex flex-row gap-3 justify-content-between align-items-center">
															{u.nailing_value?.map((v, i) => (
																<span key={i}>
																	<strong>{v?.nailing_type?.charAt(0).toUpperCase() + v?.nailing_type?.toLowerCase().slice(1)}</strong>:{" "}
																	{new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(Number(v.nailing_value))}
																</span>
															))}
														</div>
													</Card.Body>
												</Card>
											</div>
										</div>
									);
								}
							})}
						</Card.Body>
					</Card>
				) : null}

				{list.some(u => u.permission_type == 18) ? (
					<Card className="card-one">
						<Card.Header>
							<Card.Title>Cravadores Externos</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex flex-column gap-3">
							{list?.map((u, index_user_list_permission_18) => {
								if (u.permission_type == 18) {
									return (
										<div key={index_user_list_permission_18} className="">
											<div className="d-flex justify-content-between">
												<Card className="d-flex w-100 flex-row justify-content-between align-items-center">
													<Card.Body className="d-flex w-100 flex-row justify-content-between align-items-center">
														<Card.Title className="px-2 py-0 m-0 d-flex align-items-center justify-content-center">
															<ModalCravador setter={u} />
														</Card.Title>

														<div className="w-60 d-flex flex-row gap-3 justify-content-between align-items-center">
															{u.nailing_value?.map((v, index_nailing_value) => (
																<span key={index_nailing_value}>
																	<strong>{v?.nailing_type?.charAt(0).toUpperCase() + v?.nailing_type?.toLowerCase().slice(1)}</strong>:{" "}
																	{new Intl.NumberFormat("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	}).format(Number(v.nailing_value))}
																</span>
															))}
														</div>
													</Card.Body>
												</Card>
											</div>
										</div>
									);
								}
							})}
						</Card.Body>
					</Card>
				) : null}
			</div>
			<Modal size="xl" show={modalAlterarShow} onHide={() => setModalAlterarShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Alterar Valores de Cravação</Modal.Title>
				</Modal.Header>
				<form onSubmit={handleSubmitAlteraValoresCravadores}>
					<Modal.Body>
						<Card className="card-one">
							<Card.Body>
								<Card.Title> {modalAlterarData?.user_name}</Card.Title>
								<hr></hr>
								<div className="d-flex flex-row gap-3 justify-content-between">
									{nailingValue?.map((v, i) => (
										<Form.Group>
											<Form.Label>
												<strong>{v.nailing_type.charAt(0) + v.nailing_type.toLowerCase().substring(1)}</strong>
											</Form.Label>
											<Form.Control onChange={e => changeNailingValue(v.id_nailing_value, e.target.value)} value={v.nailing_value}></Form.Control>
										</Form.Group>
									))}
								</div>
							</Card.Body>
						</Card>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => setModalAlterarShow(false)} variant="secondary">
							Cancelar
						</Button>
						<Button type="submit" variant="primary">
							Salvar
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
			<Modal show={openModalNailingUser} onHide={handleCloseModalNailingUser} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>Detalhes do Pedido por Cravador</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{listaNailingUser?.map((item, index) => (
						<Card key={index} className="mb-4">
							<Card.Header className="bg-light">
								<strong>Cravador: {item.name}</strong>
							</Card.Header>
							<Card.Body>
								<Table striped bordered hover>
									<thead>
										<tr>
											<th>Pedido</th>
											<th>Pavê</th>
											<th>Grifas</th>
											<th>Central</th>
											<th>Inglesa</th>
											<th>Diferencial</th>
											<th>Completado em</th>
											<th>Atribuído em</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{item.order_code}</td>
											<td>{item.amount_pave}</td>
											<td>{item.amount_grifas}</td>
											<td>{item.amount_central}</td>
											<td>{item.amount_inglesa}</td>
											<td>{item.amount_diferencial}</td>
											<td>{item.completed_at ? new Date(item.completed_at).toLocaleDateString() : "Não completado"}</td>
											<td>{new Date(item.created_at).toLocaleDateString()}</td>
										</tr>
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					))}
				</Modal.Body>
			</Modal>
		</div>
	);
}
