import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import { apiServices } from "../../apiServices/ApiServices";
import { Card, Pagination, Table, Button, Accordion, Modal, Col, Row, OverlayTrigger, Tooltip, Alert, Form, DropdownButton, Dropdown, Badge } from "react-bootstrap";
import { productServices } from "../../apiServices/productServices";
import { Search } from "lucide-react";
import { htmlDialog, swalErro, toastErro, toastInfo, toastSucesso } from "../Swal";
import { PageService } from "../../apiServices/PageService/service";

const ListaExpedicao = ({ titulo, nextPage, status, page, buscar, addPesoPrototipagem }) => {
	const token = localStorage.getItem("token");
	const [orders, setOrders] = useState([]);

	const [pedidosSemPeso, setPedidosSemPeso] = useState([]);
	const [pedidosCravados, setPedidosCravados] = useState([]);
	const [pedidosPendentesfaturamento, setPedidosPendentesFaturamento] = useState([]);
	useEffect(() => {
		setPedidosSemPeso(orders.filter(item => item.ORDER_PAGE == page && item.ID_STATUS == status && item.POLIMENTO_WEIGHT == null));
		setPedidosCravados(orders.filter(item => item.ORDER_PAGE == page && item.ID_STATUS == status && item.ID_ORDER_NAILING != null && item.POLIMENTO_WEIGHT != null));
	}, [orders]);

	useEffect(() => {
		console.log(pedidosPendentesfaturamento);
	}, [pedidosPendentesfaturamento]);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [tooltipOpen, setTooltipOpen] = useState({});
	const [searchKey, setSearchKey] = useState("");
	const handleSearch = event => {
		event.preventDefault();
		let formData = event.target.pesquisar.value;
		if (formData.length > 1) {
			setSearchKey(formData);
		} else if (formData.length === 0) {
			setSearchKey("");
		}
	};
	const handleSearchNoneValue = event => {
		let formData = event.target.value;
		if (formData.length === 0) {
			setSearchKey("");
		}
	};
	const [totalCount, setTotalCount] = useState(0);
	const [selectedOrder, setSelectedOrder] = useState(null);

	const [modalHistoryShow, setModalHistoryShow] = useState(false);
	const [modalHistoryContent, setMOdalHistoryContent] = useState([]);

	const [modalViewShow, setModalViewShow] = useState(false);
	const [modalViewContent, setModalViewContent] = useState([]);
	const [nailing, setNailing] = useState([]);
	const [typeNailing, setTypeNailing] = useState([]);
	const [metal, setMetal] = useState([]);
	const [attachment, setAttachment] = useState([]);
	const [module, setModule] = useState([]);

	const [openModalMetalAtribuicao, setOpenModalMetalAtribuicao] = useState(false);

	const [openEditModal, setOpenEditModal] = useState(false);
	const [modalMetalAtribuicaoDetal, setModalDetalAtribuicaoDetal] = useState([]);

	const [statusToUpdate, setStatusToUpdate] = useState([]);
	const [orderDataToStatus, setOrderDataToStatus] = useState([]);

	const [ordersIds, setOrdersIds] = useState([]);

	const [modalAddPesoData, setModalAddPesoData] = useState([]);
	const [showModalAddPeso, setShowModalAddPeso] = useState(false);

	useEffect(() => {
		console.log(modalAddPesoData);
	}, [modalAddPesoData]);

	const [modalAddPesoPrototipagemData, setModalAddPesoPrototipagemData] = useState([]);
	const [showModalAddPesoPrototipagem, setShowModalAddPesoPrototipagem] = useState(false);

	const handleOpenModalAddPesoPrototipagem = e => {
		setShowModalAddPesoPrototipagem(true);
		setModalAddPesoPrototipagemData(e);
	};

	const closeModalAddPesoPrototipagem = () => {
		setShowModalAddPesoPrototipagem(false);
	};

	const handleSubmitModalAddPesoPrototipagem = async e => {
		e.preventDefault();
		const formData = new FormData(e.target);
		toast.loading("Processando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});
		const data = {
			required_weight_total: formData.get("peso-inicial"),
			order: [modalAddPesoPrototipagemData.ID],
			id_page: page,
			token: token,
		};

		const response = await apiServices.updateStatusOrder(data);

		if ((response.data.status = "success")) {
			toast.dismiss();
			toast.success(response.data.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
			fetchall();
			closeModalAddPesoPrototipagem();
		} else {
			toast.dismiss();
			toast.error(response.data.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		}
	};

	let todosAceitacao = null;

	if (orders && Array.isArray(orders) && orders.length > 0) {
		todosAceitacao = orders.every(order => order.ID_STATUS === 17);
	}

	const codigoPaginaCondicional = data => {
		if (data.NAILING_ID !== null) {
			if (page === 12) {
				return page + 1;
			}
		} else if (data.NAILING_ID === null) {
			if (page === 12) {
				return page + 2;
			}
		} else {
			return page + 1;
		}
	};

	const handleUpdateStatusOrder = async order => {
		const data = {
			token: token,
			order: [order.ID],
			id_page: page + 1,
			status: 17,
		};

		toast.loading("Processando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});

		const response = await apiServices.updateStatusOrder(data);
		if (response) {
			if (response.data.status === "success") {
				toast.dismiss();
				fetchall();
				toast.success(response.data.msg, {
					position: toast.POSITION.BOTTOM_CENTER,
				});
			}
		}
	};

	const [conforme, setConforme] = useState(null);
	const [selectedPage, setSelectedPage] = useState(null);
	const [polimentoWeight, setPolimentoWeight] = useState([]);

	useEffect(() => {
		console.log(polimentoWeight);
	}, [polimentoWeight]);

	const handleSetPolimentoWeight = (cor, peso) => {
		const obj = {
			material_color_id: cor,
			weight: peso,
		};

		// Supondo que você tenha um estado ou array que mantém as cores e pesos.
		let updatedArray = [...polimentoWeight]; // Seu array original onde os objetos estão.

		// Encontrar o índice do item com a cor passada
		const index = updatedArray.findIndex(item => item.material_color_id === cor);

		if (index !== -1) {
			if (peso == 0) {
				// Se o peso for 0, remover o item do array
				updatedArray.splice(index, 1);
			} else {
				// Se o peso for diferente de 0, atualiza o peso e a ordem
				updatedArray[index].weight = peso;
			}
		} else {
			if (peso !== 0) {
				// Caso não exista o item e o peso seja diferente de 0, adicionar o novo objeto
				updatedArray.push(obj);
			}
		}

		// Agora você pode atualizar o estado ou fazer qualquer coisa com o array atualizado
		setPolimentoWeight(updatedArray); // Supondo que você esteja usando algum setState
	};

	useEffect(() => {
		console.log(polimentoWeight);
	}, [polimentoWeight]);

	const handleSubmitModalAddPeso = async e => {
		e.preventDefault();

		const formData = new FormData(e.target);

		toast.loading("Processando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});

		const data = {
			order: [modalAddPesoData.ID],
			token: token,
		};

		if (conforme != null) {
			data.conforme = conforme;
		}

		if (modalAddPesoData.ID_TYPE_ORDER == 2) {
			if (selectedPage == null) {
				toast.dismiss();
				swalErro("Você precisa selecionar o setor");
				return;
			}
		}

		if (!modalAddPesoData.NAILING_ID && conforme) {
			/************************ PARA CASOS EM QUE A PEÇA É LISA ************************/
			// Quando a peça está conforme, setamos o status e id_page = 17
			if (polimentoWeight == null) {
				toast.dismiss();
				swalErro("Você precisa adicionar o peso do pedido!");
				return;
			}
			data.polimento_weight = polimentoWeight;
			data.id_page = 17;

			data.status = 17;
		} else if (!modalAddPesoData.NAILING_ID && !conforme) {
			// Quando não está conforme, setamos a página selecionada
			data.id_page = selectedPage;

			data.status = 17;
		}

		/************************ FIM ************************/

		/************************ PARA CASOS EM QUE A PEÇA TEM CRAVAÇÃO ************************/
		if (modalAddPesoData.NAILING_ID && modalAddPesoData.POLIMENTO_WEIGHT == null) {
			// Quando a peça tem cravação e não tem polimento inserido
			if (polimentoWeight == null) {
				toast.dismiss();
				swalErro("Você precisa adicionar o peso do pedido!");
				return;
			}
			data.polimento_weight = polimentoWeight;

			data.status = 17;
			data.id_page = 14;
		}

		if (modalAddPesoData.NAILING_ID && modalAddPesoData.ID_ORDER_NAILING && conforme) {
			// Quando está conforme, vai para faturamento

			data.id_page = 17;
			data.status = 17;
		} else if (modalAddPesoData.NAILING_ID && modalAddPesoData.ID_ORDER_NAILING && conforme == false) {
			// Quando não está conforme, vai para a página selecionada

			if (selectedPage == null) {
				toast.dismiss();
				swalErro("Você precisa selecionar o setor");
				return;
			}

			data.id_page = selectedPage;
			data.status = 17;
		}

		/************************ FIM ************************/

		const response = await apiServices.updateStatusOrder(data);

		if ((response.data.status = "success")) {
			toast.dismiss();
			toast.success(response.data.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});

			closeModalAddPeso();
			fetchall();
		} else {
			toast.dismiss();
			toast.error(response.data.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		}
	};
	const handleReset = () => {
		setPolimentoWeight([]);
		setConforme(null);
		setSelectedPage(null);
	};

	const closeModalAddPeso = () => {
		setShowModalAddPeso(false);
		handleReset();
	};

	const handleOpenModalAddPeso = e => {
		setShowModalAddPeso(true);
		setModalAddPesoData(e);
	};

	const formatDate = data => {
		const date = new Date(data);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		const seconds = String(date.getSeconds()).padStart(2, "0");

		return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
	};

	const handleOrderPdf = async id => {
		toast.loading("Processando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});
		const data = {
			id_order: id,
			token: token,
		};
		const response = await apiServices.getOrderPdf(data);
		if (response.data.link !== "") {
			var link = response.data.link;
			var anchor = document.createElement("a");
			anchor.style.display = "none";
			document.body.appendChild(anchor);

			// Configure o atributo href e download
			anchor.href = link;
			anchor.setAttribute("target", "_blank");
			toast.dismiss();
			toast.success("Documento gerado com sucesso!");
			// Dispare um clique simulado no elemento de âncora
			setTimeout(() => {
				anchor.click();
			}, 1000);

			// Remova o elemento de âncora
			document.body.removeChild(anchor);
		}
	};

	const handleOrderHistory = order => {
		apiServices.getORderHistory(order, token).then(response => {
			setMOdalHistoryContent(response.data);
			setModalHistoryShow(true);
		});
	};

	const toggleTooltip = orderId => {
		setTooltipOpen(prevState => ({
			...prevState,
			[orderId]: !prevState[orderId],
		}));
	};

	const getMetalDetails = async (order, token) => {
		const response = await apiServices.getMetal(order, token);
		if (response) {
			setMetal(response.data);
		}
	};

	const handleOrderView = async order => {
		setSelectedOrder(order);
		setModalViewShow(true);
		try {
			const orderResponse = await apiServices.getOrderView(order, token);
			setModalViewContent(orderResponse.data.order);
		} catch (error) {
			console.log(error);
		}

		await getMetalDetails(order, token);

		await apiServices
			.getModule(order, token)
			.then(response => {
				setModule(response.data);
			})
			.catch(error => console.log(error));

		await apiServices
			.getNailing(order, token)
			.then(response => {
				setNailing(response.data);
			})
			.catch(error => {
				console.log(error);
			});

		await apiServices.getTypeNailing(order, token).then(response => {
			setTypeNailing(response.data);
		});

		await apiServices
			.getAttachment(order, token)
			.then(response => {
				setAttachment(response.data);
			})
			.catch(error => console.log(error));
	};

	const handeCloseOrderView = () => {
		setModalViewShow(false);
	};

	const handleOpenEditModal = async (order, status) => {
		setOpenEditModal(true);

		const response = await apiServices.orderStatusList(order, token);
		const data = {
			order: order,
			status: status,
		};
		setStatusToUpdate(response.data);
		setOrderDataToStatus(data);
	};

	const closeEditModal = () => {
		setOpenEditModal(false);
		handleOrderView("");
	};

	const handleOpenMetalAtribuicaoModal = async metal => {
		setOpenModalMetalAtribuicao(true);

		const data = {
			metal: metal,
			order: selectedOrder,
			token: token,
		};

		const response = await productServices.getMetalDetailOrder(data);
		const modalAtribuicaoDetalData = {
			metal: metal,
			...response.data,
		};
		setModalDetalAtribuicaoDetal(modalAtribuicaoDetalData);
	};

	const getOrder = async (token, limit, currentPage, searchKey, status, page, evolution_search) => {
		try {
			const response = await apiServices.getOrder(token, limit, currentPage, searchKey, status, page, evolution_search);
			setOrders(response.data.orders);
			setTotalPages(response.data.total_pages);
			toast.dismiss();
		} catch (error) {
			toast.error(error);
			console.log(error);
		}
	};

	const getOrderPendenteFaturamento = async (token, limit, currentPage, searchKey, status = "17", page = "17", evolution_search) => {
		try {
			const response = await apiServices.getOrder(token, limit, "1", searchKey, "17", "17", evolution_search);
			setPedidosPendentesFaturamento(response.data.orders);
			setTotalPages(response.data.total_pages);
			toast.dismiss();
		} catch (error) {
			toast.error(error);
			console.log(error);
		}
	};

	async function fetchall() {
		await getOrder(token, 1000, 1, searchKey, status, page, true);
		await getOrderPendenteFaturamento(token, 1000, 1, searchKey, status, page, true);
	}

	useEffect(() => {
		fetchall();
	}, [searchKey, buscar]);

	const handleSetOrdersId = () => {
		const orderIds = orders && Array.isArray(orders) && orders.length > 0 && orders.map(order => order.ID);
		setOrdersIds(orderIds);
	};

	const hadleGetOrderHistory = async () => {
		const data = {
			token: token,
			order: ordersIds,
			page: page,
		};
		if (ordersIds.length > 0) {
			const response = await apiServices.getOrderHistory(data);
			// if (response) {
			//   ;
			// }
		}
	};

	const handlePageClick = pageNumber => {
		setCurrentPage(pageNumber);
		getOrder(token, 10, pageNumber, searchKey, status, page, true);
	};

	useEffect(() => {
		handleSetOrdersId();
	}, [orders]);

	useEffect(() => {
		hadleGetOrderHistory();
	}, [ordersIds]);

	const handleOpenDialog = async pedido => {
		const confirm = await htmlDialog(`O pedido ${pedido.CODE} está conforme?`);
		if (confirm.isConfirmed) {
			const data = {
				order: [pedido.ID],
				id_page: 25,
				status: 17,
				token: token,
				is_approved: true,
			};

			const response = await apiServices.updateStatusOrder(data);
			if (response.data.status == "success") {
				toastSucesso("Pedido atualizado com sucesso!");
			} else {
				toastErro("Erro ao atualizar pedido!");
			}
		} else {
			toastInfo("Não sei o que fazer ainda");
		}
	};
	const tabs = [
		{ id: "1", title: "Pedidos sem peso adicionado" },
		{ id: "2", title: "Pedidos cravados" },
		{ id: 3, title: "Pedidos liberados para faturamento" },
	];
	const [selectedTab, setSelectedTab] = useState("1");
	const [valueChecked, setValueChecked] = useState("conforme");

	const [pages, setPages] = useState([]);
	const [materialColor, setMaterialColor] = useState([]);

	const handleFetchPages = async () => {
		const response = await PageService.list({ token: localStorage.getItem("token") });
		setPages(response.data.result);

		const responseMaterialColor = await productServices.selectListMaterialcolor("", token);
		setMaterialColor(responseMaterialColor.data);
	};

	useEffect(() => {
		handleFetchPages();
	}, []);

	useEffect(() => {
		console.log(materialColor);
	}, [materialColor]);

	return (
		<>
			<div>
				<Card className="card-one mb-3">
					<Card.Body className="d-flex flex-row gap-3">
						{tabs?.map(tab => (
							<Button variant={selectedTab == tab.id ? "primary" : "outline-primary"} key={tab.id} onClick={() => setSelectedTab(tab.id)}>
								{tab.title}
							</Button>
						))}
					</Card.Body>
				</Card>
				<Card className="card-one d-flex">
					<Card.Header className="d-flex justify-content-between">
						<h3>{tabs.find(item => item.id == selectedTab)?.title}</h3>
					</Card.Header>
					<Card.Body>
						<div className="border form-search w-20 mb-3">
							<Form className="d-flex w-100" onSubmit={handleSearch}>
								<Form.Control className="w-100" placeholder="Pesquisar" onChange={handleSearchNoneValue} id="pesquisar" name="pesquisar"></Form.Control>
								<Button type="submit" variant="outline">
									<Search />
								</Button>
							</Form>
						</div>

						{selectedTab == 1 && (
							<Table className="table-four table-bordered">
								<thead>
									<tr>
										<th className="text-center">Cod.Pedido</th>
										<th className="text-center">Cliente</th>
										<th className="text-center">Cod.Peça</th>

										<th className="text-center">Tipo de Pedido</th>

										<th className="text-center">Ações</th>
									</tr>
								</thead>
								<tbody className="">
									{pedidosSemPeso && Array.isArray(pedidosSemPeso) && pedidosSemPeso.length > 0
										? pedidosSemPeso.map(order => {
												if (order.ID_STATUS === status && order.ID_TYPE_ORDER) {
													return (
														<tr key={order.CODE}>
															<td className="text-center">{order.CODE}</td>
															<td
																className="text-center"
																style={{
																	whiteSpace: "nowrap",
																	overflowX: "hidden",
																	textOverflow: "ellipsis",
																	textAlign: "left",
																	maxWidth: "150px",
																	zIndex: "-999",
																}}
															>
																<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${order.ID}`}>{order.NAME_CLIENT}</Tooltip>}>
																	<span
																		id={order.ID}
																		onMouseEnter={() => toggleTooltip(order.ID)}
																		onMouseLeave={() => toggleTooltip(order.ID)}
																		className="text-truncate"
																		style={{ whiteSpace: "nowrap" }}
																	>
																		{order.NAME_CLIENT}
																	</span>
																</OverlayTrigger>
															</td>
															<td className="text-center">{order.PART_CODE}</td>

															<td className="text-center">{order.TYPE_ORDER_DESCRIPTION}</td>

															<td className="text-center">
																<Button onClick={() => handleOpenModalAddPeso(order)}>Ações</Button>
															</td>
														</tr>
													);
												}
										  })
										: null}
								</tbody>
							</Table>
						)}

						{selectedTab == 2 && (
							<Table className="table-four table-bordered">
								<thead>
									<tr>
										<th className="text-center">Cod.Pedido</th>
										<th className="text-center">Cliente</th>
										<th className="text-center">Cod.Peça</th>

										<th className="text-center">Tipo de Pedido</th>

										<th className="text-center">Ações</th>
									</tr>
								</thead>
								<tbody className="">
									{pedidosCravados && Array.isArray(pedidosCravados) && pedidosCravados.length > 0
										? pedidosCravados.map(order => {
												if (order.ID_STATUS === status && order.ID_TYPE_ORDER) {
													return (
														<tr key={order.CODE}>
															<td className="text-center">{order.CODE}</td>
															<td
																className="text-center"
																style={{
																	whiteSpace: "nowrap",
																	overflowX: "hidden",
																	textOverflow: "ellipsis",
																	textAlign: "left",
																	maxWidth: "150px",
																	zIndex: "-999",
																}}
															>
																<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${order.ID}`}>{order.NAME_CLIENT}</Tooltip>}>
																	<span
																		id={order.ID}
																		onMouseEnter={() => toggleTooltip(order.ID)}
																		onMouseLeave={() => toggleTooltip(order.ID)}
																		className="text-truncate"
																		style={{ whiteSpace: "nowrap" }}
																	>
																		{order.NAME_CLIENT}
																	</span>
																</OverlayTrigger>
															</td>
															<td className="text-center">{order.PART_CODE}</td>

															<td className="text-center">{order.TYPE_ORDER_DESCRIPTION}</td>

															<td className="text-center">
																<Button onClick={() => handleOpenModalAddPeso(order)}>Ações</Button>
															</td>
														</tr>
													);
												}
										  })
										: null}
								</tbody>
							</Table>
						)}

						{selectedTab == 3 && (
							<Table className="table-four table-bordered">
								<thead>
									<tr>
										<th className="text-center">Cod.Pedido</th>
										<th className="text-center">Cliente</th>
										<th className="text-center">Cod.Peça</th>

										<th className="text-center">Tipo de Pedido</th>
									</tr>
								</thead>
								<tbody className="">
									{pedidosPendentesfaturamento?.map(order => (
										<tr key={order.CODE}>
											<td className="text-center">{order.CODE}</td>
											<td
												className="text-center"
												style={{
													whiteSpace: "nowrap",
													overflowX: "hidden",
													textOverflow: "ellipsis",
													textAlign: "left",
													maxWidth: "150px",
													zIndex: "-999",
												}}
											>
												<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${order.ID}`}>{order.NAME_CLIENT}</Tooltip>}>
													<span
														id={order.ID}
														onMouseEnter={() => toggleTooltip(order.ID)}
														onMouseLeave={() => toggleTooltip(order.ID)}
														className="text-truncate"
														style={{ whiteSpace: "nowrap" }}
													>
														{order.NAME_CLIENT}
													</span>
												</OverlayTrigger>
											</td>
											<td className="text-center">{order.PART_CODE}</td>

											<td className="text-center">{order.TYPE_ORDER_DESCRIPTION}</td>
										</tr>
									))}
								</tbody>
							</Table>
						)}
					</Card.Body>
					<Card.Footer className="d-flex justify-content-center">
						<Pagination className="pagination-space pagination-circled mb-0">
							{Array.from({ length: totalPages }, (_, index) => {
								if (index + 1 === currentPage || index + 1 === 1 || index + 1 === totalPages || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2)) {
									return (
										<Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageClick(index + 1)}>
											{index + 1}
										</Pagination.Item>
									);
								} else if ((index + 1 === currentPage - 3 && currentPage > 5) || (index + 1 === currentPage + 3 && currentPage < totalPages - 4)) {
									return <Pagination.Ellipsis key={index + 1} onClick={() => handlePageClick(index + 1)} />;
								}
								return null;
							})}
						</Pagination>
					</Card.Footer>
				</Card>
			</div>

			{orders && orders.some(order => order.ID_STATUS === 17 && order.ORDER_PAGE === page) ? (
				<div>
					<Card className="card-one mt-3">
						<Card.Header className="">
							<h4>Pedidos pendentes de evolução para {titulo}</h4>
						</Card.Header>
						<Card.Body>
							<Table className="table-four table-bordered">
								<thead>
									<tr>
										<th className="text-center">Cod.Pedido</th>
										<th className="text-center">Cliente</th>
										<th className="text-center">Cod.Peça</th>
										<th className="text-center">Lote</th>
										<th className="text-center">Data</th>
									</tr>
								</thead>
								<tbody className="">
									{orders.map((order, index) => {
										if (order.ID_STATUS === 17 && order.ORDER_PAGE === page) {
											return (
												<tr key={index}>
													<td className="text-center">{order.CODE}</td>
													<td
														className="text-center"
														style={{
															whiteSpace: "nowrap",
															overflowX: "hidden",
															textOverflow: "ellipsis",
															textAlign: "left",
															maxWidth: "150px",
															zIndex: "-999",
														}}
													>
														<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${order.ID}`}>{order.NAME_CLIENT}</Tooltip>}>
															<span
																id={order.ID}
																onMouseEnter={() => toggleTooltip(order.ID)}
																onMouseLeave={() => toggleTooltip(order.ID)}
																className="text-truncate"
																style={{ whiteSpace: "nowrap" }}
															>
																{order.NAME_CLIENT}
															</span>
														</OverlayTrigger>
													</td>
													<td className="text-center">{order.PART_CODE}</td>
													<td className="text-center">{order.BATCH}</td>
													<td className="text-center">
														{order.ENTRADO_EM ? "Entrado em: " + formatDate(order.ENTRADO_EM) : "Cadastrado em: " + formatDate(order.DATE)}
													</td>
												</tr>
											);
										}
										return null;
									})}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</div>
			) : null}

			<Modal size="lg" show={showModalAddPeso} onHide={closeModalAddPeso}>
				<Modal.Header closeButton>
					<Modal.Title>Verificação/Conformidade de pedido</Modal.Title>
				</Modal.Header>
				<form onSubmit={handleSubmitModalAddPeso}>
					<Modal.Body className="gap-3 d-flex flex-column">
						<Card className="card-one">
							<Card.Header className="d-flex flex-row justify-content-between">
								<Card.Title>Dados do Pedido</Card.Title>
								<div className="d-flex flex-row gap-2">
									<Badge type="info" className="rounded d-flex flex-row align-items-center text-center">
										<h5 className="m-auto">{modalAddPesoData.TYPE_ORDER_DESCRIPTION}</h5>
									</Badge>
									{modalAddPesoData?.NAILING_ID && (
										<Badge type="info" className="rounded d-flex flex-row align-items-center text-center">
											<h5 className="m-auto">Cravação</h5>
										</Badge>
									)}

									<Badge type="info" className="rounded d-flex flex-row align-items-center text-center">
										<h5 className="m-auto">{modalAddPesoData.MATERIAL_COLOR?.replace(" c/ Ródio", "")}</h5>
									</Badge>
								</div>
							</Card.Header>

							<Card.Body>
								<div>
									<span>
										<strong>Pedido:</strong> {modalAddPesoData.CODE}
									</span>
								</div>

								<div>
									<span>
										<strong>
											{modalAddPesoData?.POLIMENTO_WEIGHT &&
												JSON.parse(modalAddPesoData?.POLIMENTO_WEIGHT).map((item, index) => (
													<div className="d-flex flex-row gap-2">
														<span>{materialColor.find(cor => cor.id == item.material_color_id)?.name}: </span>
														<strong>{item.weight} (g)</strong>
													</div>
												))}{" "}
										</strong>
									</span>
								</div>
							</Card.Body>
						</Card>

						{!modalAddPesoData.NAILING_ID && modalAddPesoData.POLIMENTO_WEIGHT == null && modalAddPesoData.ID_TYPE_ORDER != 2 ? ( // PEÇA LISA
							<Form>
								<Form.Check type="checkbox" label="Conforme" checked={conforme === true} onChange={e => setConforme(e.target.checked ? true : null)} />
								<Form.Check type="checkbox" label="Não Conforme" checked={conforme === false} onChange={e => setConforme(e.target.checked ? false : null)} />
							</Form>
						) : null}

						{modalAddPesoData.NAILING_ID && modalAddPesoData.POLIMENTO_WEIGHT && modalAddPesoData.ID_ORDER_NAILING && modalAddPesoData.ID_TYPE_ORDER != 2 ? ( // COM CRAVAÇÃO
							<Form>
								<Form.Check type="checkbox" label="Conforme" checked={conforme === true} onChange={e => setConforme(e.target.checked ? true : null)} />
								<Form.Check type="checkbox" label="Não Conforme" checked={conforme === false} onChange={e => setConforme(e.target.checked ? false : null)} />
							</Form>
						) : null}

						{(conforme == false || modalAddPesoData.ID_TYPE_ORDER == 2) && (
							<Card className="my-3 card-one">
								<Card.Body>
									<Form.Label as={"strong"} className="mb-1">
										Selecione o setor que deseja enviar o pedido
									</Form.Label>
									<Form.Select onChange={e => setSelectedPage(e.target.value)} name="selectedPage" id="selectedPage">
										<option value="null">-- Selecione um setor --</option>
										{pages?.map(page => {
											if (![1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 18, 19, 20, 21, 22, 23, 24, 25, 26].includes(page.id)) {
												return (
													<option value={page.id} key={page.id}>
														{page.page_description}
													</option>
												);
											}
										})}
									</Form.Select>
								</Card.Body>
							</Card>
						)}

						{modalAddPesoData.ID_TYPE_ORDER == 2 && selectedPage != 14 && selectedPage != null ? ( // CONSERTO - SÓ APARECE CONFORMIDADEE QUANDO FOR PARA OUTRAS PÁGINAS
							<Card className="card-one">
								<Card.Body>
									<Form>
										<Form.Label as={"strong"} className="mb-1">
											Marque se o pedido está ou não conforme
										</Form.Label>
										<Form.Check type="checkbox" label="Conforme" checked={conforme === true} onChange={e => setConforme(e.target.checked ? true : null)} />
										<Form.Check type="checkbox" label="Não Conforme" checked={conforme === false} onChange={e => setConforme(e.target.checked ? false : null)} />
									</Form>
								</Card.Body>
							</Card>
						) : null}

						{!modalAddPesoData.NAILING_ID && modalAddPesoData.ID_TYPE_ORDER != 2 && conforme == true && modalAddPesoData.POLIMENTO_WEIGHT == null && (
							<Card>
								<Card.Body>
									<Form>
										<Form.Label as={"strong"}>Adicione o peso da peça</Form.Label>
										{modalAddPesoData.MATERIAL_COLOR_ID == 6 ? (
											materialColor
												?.filter(item => ![7, 8, 5, 3, 6].includes(item.id))
												.map(item => (
													<Form.Group>
														<Form.Label>{item.name?.replace(" c/ Ródio", "")}</Form.Label>
														<Form.Control
															type="number"
															required
															name="polimento_weight"
															id="polimento_weight"
															step="0.01"
															onChange={e => handleSetPolimentoWeight(item.id, e.target.value)}
															className="w-100"
															placeholder="Digite o peso da peça"
														/>
													</Form.Group>
												))
										) : (
											<Form.Control
												type="number"
												required
												name="polimento_weight"
												id="polimento_weight"
												step="0.01"
												onChange={e => handleSetPolimentoWeight(modalAddPesoData.MATERIAL_COLOR_ID, e.target.value)}
												className="w-100"
												placeholder="Digite o peso da peça"
											/>
										)}
									</Form>
								</Card.Body>
							</Card>
						)}

						{modalAddPesoData.ID_TYPE_ORDER == 2 && conforme == true && modalAddPesoData.POLIMENTO_WEIGHT == null && (
							<Card className="card-one">
								<Card.Body>
									<Form>
										<Form.Label as={"strong"}>Adicione o peso da peça</Form.Label>
										{modalAddPesoData.MATERIAL_COLOR_ID == 6 ? (
											materialColor
												?.filter(item => ![7, 8, 5, 3, 6].includes(item.id))
												.map(item => (
													<Form.Group>
														<Form.Label>{item.name?.replace(" c/ Ródio", "")}</Form.Label>
														<Form.Control
															type="number"
															required
															name="polimento_weight"
															id="polimento_weight"
															step="0.01"
															onChange={e => handleSetPolimentoWeight(item.id, e.target.value)}
															className="w-100"
															placeholder="Digite o peso da peça"
														/>
													</Form.Group>
												))
										) : (
											<Form.Control
												type="number"
												required
												name="polimento_weight"
												id="polimento_weight"
												step="0.01"
												onChange={e => handleSetPolimentoWeight(modalAddPesoData.MATERIAL_COLOR_ID, e.target.value)}
												className="w-100"
												placeholder="Digite o peso da peça"
											/>
										)}
									</Form>
								</Card.Body>
							</Card>
						)}

						{modalAddPesoData.NAILING_ID && modalAddPesoData.POLIMENTO_WEIGHT == null && (
							<Card className="card-one">
								<Card.Body>
									<Form>
										<Form.Label as={"strong"}>Adicione o peso da peça</Form.Label>
										{modalAddPesoData.MATERIAL_COLOR_ID == 6 ? (
											materialColor
												?.filter(item => ![7, 8, 5, 3, 6].includes(item.id))
												.map(item => (
													<Form.Group>
														<Form.Label>{item.name?.replace(" c/ Ródio", "")}</Form.Label>
														<Form.Control
															type="number"
															required
															name="polimento_weight"
															id="polimento_weight"
															step="0.01"
															onChange={e => handleSetPolimentoWeight(item.id, e.target.value)}
															className="w-100"
															placeholder="Digite o peso da peça"
														/>
													</Form.Group>
												))
										) : (
											<Form.Control
												type="number"
												required
												name="polimento_weight"
												id="polimento_weight"
												step="0.01"
												onChange={e => handleSetPolimentoWeight(modalAddPesoData.MATERIAL_COLOR_ID, e.target.value)}
												className="w-100"
												placeholder="Digite o peso da peça"
											/>
										)}
									</Form>
								</Card.Body>
							</Card>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button type="submit">Salvar</Button>
						<Button
							variant="secondary"
							onClick={() => {
								closeModalAddPeso();
							}}
						>
							Cancelar
						</Button>
					</Modal.Footer>
				</form>
			</Modal>
		</>
	);
};

export default ListaExpedicao;
