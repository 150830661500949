import api from "./api";

export const apiServices = {
  getOrder: (
    token,
    limit,
    page,
    search,
    id_status,
    orderPage,
    evolution_search,
    acabamento_user,
    idUser,
    order_type,
    requestSearch) => {
    return api.post("/orderController.php", {
      action: "read",
      order_type: order_type,
      page: page,
      selectedUser: idUser,
      order_page: orderPage,
      limit: limit,
      evolution_search: evolution_search,
      search: search,
      token: token,
      acabamento_user: acabamento_user,
      id_status: id_status,
      requestSearch: requestSearch
    });
  },

  createOrderNew: async (data) => {
    const filesBase64 = await convertFilesToBase64(data.file, data.fileName); // Convertendo os arquivos para Base64

    const jsonData = {
      action: 'create_order_fundicao',
      description: data.descricao,
      id_client: data.cliente,
      id_category: data.categoria,
      amount: data.quantidade,
      weight: data.peso,
      fix_part_code: data.codicoPeca,
      id_material: data.material,
      id_material_color: data.cor,
      files: filesBase64,
      token: data.token
    };

    try {
      const response = await api.post('/orderController.php', jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getOrderHistory: (data) => {
    return api.post("/orderController.php", {
      action: "get_order_history",
      token: data.token,
      page: data.page,
      order_ids: data.order,
    });
  },

  createOrder: (data) => {
    const request = {
      action: "create_order",
      id_product: data.id_product,
      order_type: data.order_type,
      id_status: data.id_status,
      product_metal: data.product_metal,
      product_metal_color: data.product_metal_color,
      amount_product: data.amount_product,
      amount_product_metal: data.amount_product_metal,
      amount_product_metal_total: data.amount_product_metal_total,
      ordder_description: data.ordder_description,
      token: data.token,
      id_client: data.id_client,
      pair: data.pair,
    };
    return api.post("/productController.php", request);
  },

  getORderHistory: (order, token) => {
    return api.post("/orderController.php", {
      action: "orderHistory",
      id_order: order,
      token: token,
    });
  },

  getOrderView: (order, token) => {
    return api.post("/orderController.php", {
      action: "orderView",
      token: token,
      id_order: order,
    });
  },
  getCountStatus: (token) => {
    return api.post("/homeController.php", {
      action: "countStatus",
      token: token,
    });
  },

  orderStatusList: (order, token) => {
    return api.post("/orderController.php", {
      action: "selectList",
      type: "order_status_list",
      id_order: order,
      token: token,
    });
  },

  getMetal: (order, token) => {
    return api.post("/orderController.php", {
      action: "list",
      type: "metal",
      id_order: order,
      token: token,
    });
  },

  getModule: (order, token) => {
    return api.post("/orderController.php", {
      action: "list",
      type: "module",
      id_order: order,
      token: token,
    });
  },

  getNailing: (order, token) => {
    return api.post("/orderController.php", {
      action: "list",
      type: "nailing",
      id_order: order,
      token: token,
    });
  },

  getTypeNailing: (order, token) => {
    return api.post("/orderController.php", {
      action: "list",
      type: "type_nailing",
      id_order: order,
      token: token,
    });
  },

  getAttachment: (order, token) => {
    return api.post("/orderController.php", {
      action: "list",
      type: "attachment",
      id_order: order,
      token: token,
    });
  },

  // ########################### CLIENT SERVICES ###########################
  getClients: (id) => {
    return api.post("/profileController.php", {
      action: "data",
      id_user: id,
    });
  },

  getOrderPdf: (data) => {
    return api.post("/orderController.php", {
      action: "orderPDF",
      id_order: data.id_order,
      token: data.token,
    });
  },

  pdfOrders: (data) => api.post("/orderController.php", {
    action: "pdfOrders",
    token: data.token,
    id_orders: data.id_orders,
  }),

  getOrderFixPdf: (data) => {
    return api.post("/repairController.php", {
      action: "repairPDF",
      id_order: data.id_order,
      token: data.token,
    });
  },

  getOrderFundicaoPdf: (data) => {
    return api.post("/repairController.php", {
      action: "fundicaoPDF",
      id_order: data.id_order,
      token: data.token,
    });
  },

  updateStatusOrder: (data) => {
    const dados = {
      action: "orderUpdateStatus",
      id_orders: data.order,
      config_data: data.config_data,
      mainetance: data.mainetance,
      id_status: data.status,
      cobra_rodio: data.cobra_rodio,
      cravacoes: data.cravacoes,
      operator: data.operator,
      id_page: data.id_page,
      cobra_ouro: data.cobra_ouro,
      cobra_prata: data.cobra_prata,
      required_weight_total: data.required_weight_total,
      final_weight: data.peso_final,
      rodio: data.rodio,
      permission_code: data.permission_code,
      operation_code: data.operation_code,
      operation_type: data.operation_type,
      total_weight: data.total_weight,
      total_acrecimos: data.total_acrecimos,
      adicionais: data.adicionais,
      montagem: data.montagem,
      corrente: data.corrente,
      total_frete: data.total_frete,
      acabamento_initial_weight: data.acabamento_peso_inicial,
      acabamento_final_weight: data.acabamento_peso_final,
      cravacao_initial_weight: data.cravacao_peso_inicial,
      type_mold: data.molde,
      movimentation_type: data.movimentation_type,
      detail: data.detail,
      is_approved: data.is_approved,
      mold_weight: data.peso_molde,
      required_tree_weight: data.quantidadeNecessaria,
      tree: data.arvore,
      selectedUser: data.selectedUser,
      tube: data.tubo,
      id_type_order: data.id_type_order,
      batch: data.lote,
      description: data.description,
      fechamento_nota: data.fechamento_nota,
      polimento_weight: data.polimento_weight,
      token: data.token,
    };
    return api.post("/orderController.php", dados);
  },

  getCountOrderInInitialStatus: (data) => api.post("/orderController.php", data),

  getTree: (data) => {
    return api.post("/orderController.php", {
      action: "tree",
      token: data.token,
    });
  },
  getIndextree: (data) => {
    return api.post("/orderController.php", {
      action: "tree",
      token: data.token,
      batch: data.batch,
    });
  },

  getBatch: (data) => {
    return api.post("orderController.php", {
      action: "batch",
      id_page: data.id_page,
      token: data.token,
    });
  },

  getOrderBatch: (data) => {
    const dados = {
      action: "order_batch",
      batch: data.batch,
      token: data.token,
    };
    return api.post("orderController.php", dados);
  },

  updateBatch: (data) => {
    const dados = {
      action: "update_batch",
      batch_id: data.batch_id,
      initial_weight: data.peso_estimado,
      type_mold: data.tipo_molde,
      situation: data.situacao,
      mold_weight: data.peso_molde,
      token: data.token,
    };
    return api.post("orderController.php", dados);
  },

  addPesoFinal: (data) => {
    const dados = {
      action: "order_add_final_weight",
      order_id: data.order_id,
      final_weight: data.peso_final,
      token: data.token,
    };

    return api.post("orderController.php", dados);
  },
  getPage: (data) => {
    const dados = {
      action: "get_page",
      page_id: data.page,
      token: data.token,
    };
    return api.post("orderController.php", dados);
  },

  getCart: (data) => {
    const dados = {
      action: "cart_get",
      token: data,
    };

    return api.post("orderController.php", dados);
  },

  removeOrder: (data) => {
    const dados = {
      action: "cart_remove",
      token: data.token,
      id_order: data.id_order,
    };

    return api.post("orderController.php", dados);
  },

  closeCart: (data) => {
    const dados = {
      action: "cart_close",
      token: data,
    };

    return api.post("orderController.php", dados);
  },

  saveRetail: (data) => {
    const dados = {
      action: "save_retail",
      token: data.token,
      retail: data.retalho,
      batch: data.batch,
    };
    return api.post("orderController.php", dados);
  },

  FinishOrder: (data) => {
    const payload = {
      "action": data.action,
      "id_orders": [data.order],
      "token": data.token,
      "order_type": data.id_type_order
    }


  }



  // ##################### PRODUCT SERVICES ###########################
};



const convertFilesToBase64 = async (files, name) => {


  const promises = files.map((file) => {
    if (!file || !file.preview) {
      console.error('Invalid file object or missing preview property:', file);
      return null;
    }

    return new Promise((resolve, reject) => {
      const extension = file.path.slice(-4);
      fetch(file.preview)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result.split(',')[1];
            resolve({
              fileName: name + extension || 'Unknown',
              file: base64data,
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error('Error fetching file for conversion:', error);
          reject(error);
        });
    });
  });

  try {
    const base64Files = await Promise.all(promises);
    return base64Files.filter((file) => file !== null);
  } catch (error) {
    console.error('Error converting files to base64:', error);
    throw error;
  }
};


