import api from "../api";

const route = "nailingController.php";

export const NailingServices = {
  getOrderNailing: data => api.post(route, data),
  getTotalValue: data => api.post(route, data),
  NailingBreak: data => api.post(route, data),
  SaveSolicitacao: data => api.post(route, data),
  listSolicitacao: data => api.post(route, data),
  detalhesSolicitacao: data => api.post(route, data),
  ListRequestStatus: data => api.post(route, data),
  AtualizaSolicitacao: data => api.post(route, data),
  RecebimentoItens: data => api.post(route, data),
  ResendRequestEmail: data => api.post(route, data),
  ListaSolicitacaoCliente: data => api.post(route, data),
  listRequestFiles: data => api.post(route, data),
  SaveEditingNailing: data => api.post(route, data),
  saveConcluedOrCanceled: data => api.post(route, data),
  getOrderNailingUser: data => api.post(route, data),

  SaveRequestItemFile: (data) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onload = (event) => {
        const fileBase64 = event.target.result.split(',')[1];

        const jsonData = {
          action: "save_request_item_file",
          token: data.token,
          id_request_item: data.id_request_item,
          file_name: data.file_name,
          file: fileBase64,
        };

        api.post('/productController.php', jsonData, {
          headers: {
            'Content-Type': 'application/json', // Configurar o tipo de conteúdo como application/json
          },
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      };

      fileReader.onerror = (error) => {
        reject(error);
      };

      fileReader.readAsDataURL(data.anexo); // Lê o arquivo como Data URL
    });

  },
}