import api from "../api"

export const NotaService = {
  GetNotas: (payload) => {
    return api.post("/notaController.php", payload)
  },
  GeraExcel: (payload) => api.post("/notaController.php", payload, {
    responseType: "blob"
  }),
  GetNotaDetalhes: (token, id_nota) => {
    return api.post("/notaController.php", {
      token: token,
      action: "get_nota_detalhes",
      id_nota: id_nota
    })
  },
  GeraPdfNota: (token, id_nota) => {
    return api.post("/notaController.php", {
      token: token,
      action: "gera_pdf_nota",
      id_nota: id_nota
    })
  },
  CalcularNotas: (payload) => api.post("/notaController.php", payload),
  AlteraStatusNota: (payload) => api.post("/notaController.php", payload),
  ListaConfiguracoes: (payload) => api.post("/notaController.php", payload),

}