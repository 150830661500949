import React, { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Button, OverlayTrigger, Card, Col, Row, Nav, Table, Tooltip, ProgressBar, Form, Tab, NavLink, NavItem, Modal, Accordion, Pagination, Spinner, Fade, ModalHeader } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { apiServices } from "../apiServices/ApiServices";
import { Search } from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./styles/styles.css";
import { ToastContainer, toast } from "react-toastify";
import { productServices } from "../apiServices/productServices";
import { userServices } from "../apiServices/userServices";
import { FixServices } from "../apiServices/FixServices";
import MultipleFileUpload from "../components/MultipleFileUpload";
import { ModalViewImage } from "../components/ModalViewImage";
import OrderServices from "../apiServices/OrderServices";
import { ScreenContext } from "../Context/Screen/context";
import { LogModal } from "../components/LogModal/LogModal";

export default function PedidosFundicao() {
	const token = localStorage.getItem("token");
	const orderType = 3;
	const utp = localStorage.getItem("utp");
	const [count, setCount] = useState([]);
	const [orders, setOrders] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [tooltipOpen, setTooltipOpen] = useState({});
	const [searchKey, setSearchKey] = useState("");
	const [totalCount, setTotalCount] = useState(0);
	const [selectedOrder, setSelectedOrder] = useState(null);

	const [modalHistoryShow, setModalHistoryShow] = useState(false);
	const [modalHistoryContent, setMOdalHistoryContent] = useState([]);

	const [modalViewShow, setModalViewShow] = useState(false);
	const [modalViewContent, setModalViewContent] = useState([]);
	const [metal, setMetal] = useState([]);
	const [attachment, setAttachment] = useState([]);
	const [modalAttachment, setModalAttachment] = useState(false);
	const [selectedAttachment, setSelectedAttachment] = useState([]);

	const [orderLoading, setOrderLoading] = useState(true);
	const [orderViewLoading, setOrderViewLoading] = useState(false);

	const [openModalMetalAtribuicao, setOpenModalMetalAtribuicao] = useState(false);

	const [openEditModal, setOpenEditModal] = useState(false);
	const [modalMetalAtribuicaoDetal, setModalDetalAtribuicaoDetal] = useState([]);

	const [statusToUpdate, setStatusToUpdate] = useState([]);
	const [orderDataToStatus, setOrderDataToStatus] = useState([]);
	const [statusSelecionado, setStatuSelecionado] = useState([]);

	const [materialColor, setMaterialColor] = useState([]);
	const [modalFinalizacao, setModalFinalizacao] = useState(false);
	const [metals, setMetals] = useState([]);

	const [users, setUsers] = useState([]);
	const [categoria, setCategoria] = useState([]);

	const [fixHistory, setFixHistory] = useState([]);

	const [modalConsertoShow, setModalConsertoShow] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);

	const { screenWidth, handleResize } = useContext(ScreenContext);
	useEffect(() => {
		handleResize();
	}, []);

	const handleOpenMetalAtribuicaoModal = async metal => {
		setOpenModalMetalAtribuicao(true);

		const data = {
			metal: metal,
			order: selectedOrder,
			token: token,
		};

		const response = await productServices.getMetalDetailOrder(data);
		const modalAtribuicaoDetalData = {
			metal: metal,
			...response.data,
		};
		setModalDetalAtribuicaoDetal(modalAtribuicaoDetalData);
	};

	const [modalAttachmentShow, setModalAttachmentShow] = useState(false);

	const handleOpenModalAttachment = data => {
		setModalAttachment(data);
		setModalAttachmentShow(true);
	};

	const handleCloseModalAttachment = () => {
		setModalAttachment([]);
		setModalAttachmentShow(false);
	};

	const handleSelectedStatus = e => {
		// Converte 'e' para um número
		const id = parseInt(e, 10);

		const statusEncontrado = statusToUpdate.find(status => status.id === id);

		if (statusEncontrado) {
			const data = {
				id: e,
				name: statusEncontrado.name,
			};
			setStatuSelecionado(data);
		} else {
			console.log(`Nenhum status encontrado para o ID ${id}`);
			// Lide com a situação em que o objeto não foi encontrado, por exemplo, definindo um valor padrão.
			// Ou execute outra ação apropriada.
		}
	};

	const handleUpdateStatus = async e => {
		toast.loading("Processando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});
		e.preventDefault();

		const formData = new FormData(e.target);

		const data = {
			status: statusSelecionado.id,
			order: selectedOrder,
			token: token,
			description: formData.get("description"),
		};

		const response = await apiServices.updateStatusOrder(data);
		if (response.data.status === "success") {
			toast.dismiss();
			toast.success(response.data.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		} else {
			toast.dismiss();
			toast.error(response.data.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		}
	};

	const handleAtribuirMetal = async e => {
		toast.loading("Processando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});

		e.preventDefault();

		const formData = new FormData(e.target);
		const data = {
			metal: formData.get("metal"),
			order: selectedOrder,
			token: token,
			text: formData.get("modal_form_metal_input_text"),
		};
		const response = await productServices.atribuirMetal(data);

		if (response.data.status === "success") {
			toast.dismiss();
			toast.success(response.data.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
			setTimeout(() => setOpenModalMetalAtribuicao(false), 2000);
		} else {
			toast.dismiss();
			toast.error(response.data.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		}
	};

	const handleOpenEditModal = async (order, status) => {
		setOpenEditModal(true);

		const response = await apiServices.orderStatusList(order, token);
		const data = {
			order: order,
			status: status,
		};
		setStatusToUpdate(response.data);
		setOrderDataToStatus(data);
	};

	const closeEditModal = () => {
		setOpenEditModal(false);
		handleOrderView("");
	};

	// Função para alternar a visibilidade do tooltip para uma linha específica
	const toggleTooltip = orderId => {
		setTooltipOpen(prevState => ({
			...prevState,
			[orderId]: !prevState[orderId],
		}));
	};

	const formatDate = data => {
		const date = new Date(data);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		const seconds = String(date.getSeconds()).padStart(2, "0");

		return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
	};

	const handleOrderPdf = async id => {
		toast.loading("Processando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});
		const data = {
			id_order: id,
			token: token,
		};
		const response = await apiServices.getOrderFundicaoPdf(data);
		if (response.data.link !== "") {
			var link = response.data.link;
			var anchor = document.createElement("a");
			anchor.style.display = "none";
			document.body.appendChild(anchor);

			// Configure o atributo href e download
			anchor.href = link;
			anchor.setAttribute("target", "_blank");
			toast.dismiss();
			toast.success("Documento gerado com sucesso!");
			// Dispare um clique simulado no elemento de âncora
			setTimeout(() => {
				anchor.click();
			}, 1000);

			// Remova o elemento de âncora
			document.body.removeChild(anchor);
		}
	};

	const getClients = async token => {
		const responseClients = await userServices.userSearch(token);
		setUsers(responseClients.data);
	};

	const handleSearch = event => {
		event.preventDefault();
		let formData = event.target.pesquisar.value;
		if (formData.length > 1) {
			setSearchKey(formData);
		} else if (formData.length === 0) {
			setSearchKey("");
		}
	};

	const handleSearchNoneValue = event => {
		let formData = event.target.value;
		if (formData.length === 0) {
			setSearchKey("");
		}
	};

	const handleClickStatus = event => {
		setOrderLoading(true);
		setSearchKey(event.target.value);
	};

	const handleOrderHistory = order => {
		FixServices.getOrderHistory(order, token).then(response => {
			setMOdalHistoryContent(response.data);
			setModalHistoryShow(true);
		});
	};

	const handleClose = () => {
		setModalHistoryShow(false);
		setOpenEditModal(false);
	};

	const closeModalMetalAtribuicao = () => {
		setOpenModalMetalAtribuicao(false);
	};

	const getMetalDetails = async (order, token) => {
		const response = await apiServices.getMetal(order, token);
		if (response) {
			setMetal(response.data);
		}
	};

	const handleFileChange = files => {
		setSelectedFiles(files); // Função para atualizar o estado do componente pai com os arquivos selecionados
	};

	const handleModalViewImage = data => {
		setModalAttachment(true);
		setModalViewShow(false);
		setSelectedAttachment(data);
	};

	const handleCloseModalView = () => {
		setModalViewShow(true);
		setSelectedAttachment([]);
		setModalAttachment(false);
	};

	const ajaxNewOrder = async e => {
		toast.loading("Procesando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});
		e.preventDefault();

		const formData = new FormData(e.target);
		const formDataObject = {
			action: "create_order_fundicao",
			descricao: formData.get("descricao"),
			cliente: formData.get("cliente"),
			codicoPeca: formData.get("codicoPeca"),
			quantidade: formData.get("amount"),
			material: formData.get("material"),
			order_type: 3,
			cor: formData.get("cor"),
			file: selectedFiles,
			fileName: formData.get("codicoPeca"),
			token: token,
		};

		const response = await apiServices.createOrderNew(formDataObject);

		if (response.status === "error") {
			toast.dismiss();
			toast.error(response.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		} else if (response.status === "success") {
			setModalConsertoShow(false);
			toast.dismiss();
			fetchall();
			toast.success(response.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		} else {
			toast.dismiss();
			toast.info(response.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		}
	};

	const ajaxFinalizacao = async e => {
		e.preventDefault();
		toast.loading("Procesando...", {
			position: toast.POSITION.BOTTOM_CENTER,
		});
		const formData = new FormData(e.target);

		const data = {
			descricao: formData.get("descricao"),
			id_order: selectedOrder,
			peso: formData.get("weight"),
			file: selectedFiles,
			token: token,
		};

		const response = await FixServices.finallyOrder(data);
		if (response.erro === "1") {
			toast.dismiss();
			toast.error(response.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		} else if (response.erro === "0") {
			setModalConsertoShow(false);
			setModalFinalizacao(false);
			getOrder(token, 10, 1, searchKey);
			toast.dismiss();
			toast.success(response.msg, {
				position: toast.POSITION.BOTTOM_CENTER,
			});
		}

		// finallyOrder
	};

	const handleNewFix = () => {
		setModalConsertoShow(true);
		getClients(token);
		handleMaterialColor(token);
		handleMaterialProduct(token);
		handleGetCategory(token);
	};

	const handleOrderView = async order => {
		setSelectedOrder(order);
		setModalViewShow(true);
		try {
			setOrderViewLoading(true);
			const orderResponse = await FixServices.getOrderView(order, token);
			setModalViewContent(orderResponse.data.order);

			setOrderViewLoading(false);
		} catch (error) {
			setOrderViewLoading(false);
		}

		await FixServices.getAttachment(order, token)
			.then(response => {
				setAttachment(response.data);
			})
			.catch(error => console.log(error));

		await FixServices.getOrderHistory(order, token)
			.then(response => {
				setFixHistory(response.data);
			})
			.catch(error => console.log(error));
	};

	const handeCloseOrderView = () => {
		setModalViewShow(false);
		setAttachment([]);
		setFixHistory([]);
	};

	const handleFinalizacaoView = () => {
		setModalFinalizacao(true);
		setModalViewShow(false);
	};

	const handleCloseModalFinalizacao = () => {
		setModalFinalizacao(false);
		setModalViewShow(true);
	};

	const handleCloseConsertoModal = () => {
		setModalConsertoShow(false);
	};

	const getOrder = async (token, limit, currentPage, searchKey) => {
		setOrderLoading(true);

		try {
			const response = await OrderServices.fundicaoOrder(token, limit, currentPage, searchKey, null, null, null, null, null, 3);
			setOrders(response.data.orders);
			setTotalPages(response.data.total_pages);
			setTotalCount(response.data.total_count);
			setOrderLoading(false);
			toast.dismiss();
		} catch (error) {
			toast.error(error);
			console.log(error);
		}
	};

	const getCountStatus = async token => {
		await FixServices.getCountStatus(token)
			.then(response => {
				setCount(response.data);
			})
			.catch(error => {
				console.error(error);
			});
	};

	const handleMaterialColor = async () => {
		const responseMaterialColor = await productServices.selectListMaterialcolor("", token);
		setMaterialColor(responseMaterialColor.data);
	};

	const handleMaterialProduct = async token => {
		const response = await productServices.selectListMaterial("", token);
		setMetals(response.data);
	};

	const handleGetCategory = async token => {
		const response = await productServices.getCategory(token);
		setCategoria(response.data);
	};

	///// Skin Switch /////
	const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
	const [skin, setSkin] = useState(currentSkin);

	const switchSkin = skin => {
		if (skin === "dark") {
			const btnWhite = document.getElementsByClassName("btn-white");

			for (const btn of btnWhite) {
				btn.classList.add("btn-outline-primary");
				btn.classList.remove("btn-white");
			}
		} else {
			const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

			for (const btn of btnOutlinePrimary) {
				btn.classList.remove("btn-outline-primary");
				btn.classList.add("btn-white");
			}
		}
	};

	async function fetchall() {
		await getOrder(token, 10, 1, searchKey);
	}

	switchSkin(skin);

	useEffect(() => {
		switchSkin(skin);
	}, [skin]);
	// PEGA PEDIDOS POR STATUS E TOTAL
	useEffect(() => {
		fetchall();
	}, [searchKey]);

	useEffect(() => {
		getCountStatus(token);
	}, [token]);

	const handlePageClick = pageNumber => {
		setCurrentPage(pageNumber);
		getOrder(token, 10, pageNumber, searchKey);
	};

	return (
		<React.Fragment>
			<Header onSkin={setSkin} />
			<div className="main main-app p-3 p-lg-4">
				<Row className="g-3">
					<Col xl="12">
						<Card className="card-one">
							<Card.Header className="d-flex">
								<Card.Title as="h6" style={{ minWidth: "120px" }}>
									<i className="ri-shopping-cart-line"></i> Últimos Pedidos de Fundição
								</Card.Title>
							</Card.Header>
							<Card.Body>
								<div className="d-flex flex-wrap align-items-center justify-content-between">
									<div className={`border form-search mb-3 ${screenWidth < 1100 ? "w-100" : "w-20"}`}>
										<Form className="d-flex w-100" onSubmit={handleSearch}>
											<Form.Control className="w-100" placeholder="Pesquisar" onChange={handleSearchNoneValue} id="pesquisar" name="pesquisar"></Form.Control>
											<Button type="submit" variant="outline">
												<Search />
											</Button>
										</Form>
									</div>
									{utp === "interno" ? (
										<div className="w-10 mb-3 card-one">
											<Button className="w-100" onClick={() => handleNewFix()}>
												Cadastrar
											</Button>
										</div>
									) : null}
								</div>
								<Table className="table-four table-bordered">
									<thead>
										<tr>
											<th className="text-center">Imagem</th>
											<th className="text-center">Cod.Pedido</th>

											<th className="text-center">Cliente</th>
											<th className="text-center">Código da Peça</th>
											<th className="text-center">Data</th>
											<th className="text-center">Status</th>
											<th className="text-center">Ações</th>
										</tr>
									</thead>
									<tbody className="w-100 h-100">
										{orderLoading ? (
											<tr className="w-100 h-100">
												<td rowSpan={10} colSpan={6}>
													<Card className="w-100 h-100 d-flex text-center align-items-center justify-content-center">
														<Card.Body className="w-100 h-100 d-flex text-center align-items-center justify-content-center">
															<ReactLoading type="bars" color={"#506FD9"} />
														</Card.Body>
													</Card>
												</td>
											</tr>
										) : (
											<>
												{orders && orders.length > 0 ? (
													orders.map(order => (
														<tr key={order.ID}>
															<td
																className="text-center d-flex justify-content-center"
																style={{
																	cursor: "pointer",
																}}
																onClick={e => handleOpenModalAttachment(order.FILE)}
															>
																<div className="product-thumb">
																	<img width={150} src={order.FILE} alt="" />
																</div>
															</td>
															<td className="text-center">{order.CODE}</td>

															<td
																style={{
																	textAlign: "left",
																	maxWidth: "150px",
																	zIndex: "-999",
																}}
															>
																<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${order.ID}`}>{order.NAME_CLIENT}</Tooltip>}>
																	<span id={order.ID} onMouseEnter={() => toggleTooltip(order.ID)} onMouseLeave={() => toggleTooltip(order.ID)}>
																		{order.NAME_CLIENT.length > 31 ? order.NAME_CLIENT.slice(0, 31) + "..." : order.NAME_CLIENT}
																	</span>
																</OverlayTrigger>
															</td>
															<td className="text-center">{order.FIX_PART_CODE}</td>
															<td className="text-center">{formatDate(order.DATE)}</td>
															<td className="text-center">{`${order.STATUS} ${
																order.ORDER_PAGE_NAME != null && order.ID_STATUS == 17 ? `(${order.ORDER_PAGE_NAME})` : ""
															}`}</td>
															<td className="text-center justify-content-center align-items-center d-flex">
																<Button
																	className="mx-1 btn-icon"
																	value={order.ID}
																	onClick={() => {
																		handleOrderPdf(order.ID);
																	}}
																>
																	<i className="ri-printer-line fs-18 lh-1"></i>
																</Button>
																<LogModal order={order} />
																<Button className="mx-1 btn-icon" value={order.ID} onClick={() => handleOrderView(order.ID)}>
																	<i className="ri-history-line"></i>
																</Button>
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan={6} className="text-center">
															Nenhum dado cadastrado.
														</td>
													</tr>
												)}
											</>
										)}
									</tbody>
								</Table>
								<Modal size="xl" show={modalHistoryShow} onHide={handleClose}>
									<Modal.Header closeButton>
										<Modal.Title>Histórico de pedido</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Table className="w-100 p-4">
											<thead>
												<tr>
													<th>Status</th>
													<th>Usuário</th>
													<th>Descrição</th>
													<th>Data e Hora</th>
												</tr>
											</thead>
											<tbody>
												{modalHistoryContent.map(content => (
													<tr key={content.id}>
														<td>{content.status}</td>
														<td>{content.name}</td>
														<td>{content.description}</td>
														<td>{formatDate(content.dh)}</td>
													</tr>
												))}
											</tbody>
										</Table>
									</Modal.Body>

									<Modal.Footer>
										<Button variant="secondary" onClick={handleClose}>
											Cancelar
										</Button>
										<Button variant="primary" onClick={handleClose}>
											Salvar
										</Button>
									</Modal.Footer>
								</Modal>

								<Modal size="xl" show={modalViewShow} onHide={handeCloseOrderView}>
									<Modal.Header closeButton>
										<Modal.Title>Detalhes do Pedido</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Row>
											<Col>
												<Card className="h-100 card-one">
													<Card.Header className="">
														<strong className="text-uppercase">Dados do Cliente</strong>
													</Card.Header>
													<Card.Body className="px-4">
														<dl className="row">
															<dt className="col-sm-3 mb-0">Nome:</dt>
															<dd className="col-sm-9 mb-0">{modalViewContent.client_name}</dd>

															<dt className="col-sm-3 mb-0">Telefone:</dt>
															<dd className="col-sm-9 mb-0">
																<a
																	style={{
																		color: "green",
																	}}
																	href={"https://wa.me/55" + modalViewContent.client_phone}
																>
																	<i className="ri-whatsapp-line"></i> {modalViewContent.client_phone}
																</a>
															</dd>

															<dt className="col-sm-3 mb-0">Email:</dt>
															<dd className="col-sm-9 mb-0">{modalViewContent.client_email}</dd>

															<dt className="col-sm-3 mb-0">Endereço:</dt>
															<dd className="col-sm-9 mb-0">
																{modalViewContent.client_address_street}, {modalViewContent.client_address_number}
															</dd>

															<dt className="col-sm-3 mb-0">Bairro:</dt>
															<dd className="col-sm-9 mb-0">{modalViewContent.client_address_neighborhood}</dd>

															<dt className="col-sm-3 mb-0">Código do Cliente:</dt>
															<dd className="col-sm-9 mb-0">{modalViewContent.client_code}</dd>
														</dl>
													</Card.Body>
												</Card>
											</Col>
											<Col>
												<Card className="card-one">
													<Card.Header>
														<strong>DADOS DO PEDIDO</strong>
													</Card.Header>
													<Card.Body>
														<dl className="row">
															<dt className="col-sm-3 mb-0">Numero:</dt>
															<dd className="col-sm-9 mb-0">{modalViewContent.order_code}</dd>

															<dt className="col-sm-3 mb-0">Tipo:</dt>
															<dd className="col-sm-9 mb-0">Reparo</dd>

															<dt className="col-sm-3 mb-0">Status:</dt>
															<dd className="col-sm-9 mb-0">{modalViewContent.status_name}</dd>

															<dt className="col-sm-3 mb-0">Data:</dt>
															<dd className="col-sm-9 mb-0">{formatDate(modalViewContent.date)}</dd>

															<dt className="col-sm-3 mb-0">Quantidade:</dt>
															<dd className="col-sm-9 mb-0">{modalViewContent.amount}</dd>

															<dt className="col-sm-3 mb-0">Peso Final:</dt>
															<dd className="col-sm-9 mb-0">{modalViewContent.weight_final}</dd>
														</dl>
													</Card.Body>
												</Card>
											</Col>
										</Row>

										<Row>
											<Col md="12" className="mt-3 d-flex">
												<Card className="card-one w-100">
													<Card.Header className="d-flex justify-content-between">
														<span>Inicio</span>
													</Card.Header>
													<Card.Body className="">
														<Card>
															<Card.Header>Descrição Inicial</Card.Header>
															<Card.Body>
																<p
																	className="w-100"
																	style={{
																		whiteSpace: "pre-wrap",
																	}}
																	dangerouslySetInnerHTML={{
																		__html: modalViewContent.DESCRIPTION,
																	}}
																></p>
															</Card.Body>
														</Card>
														<Card className="mt-3">
															<Card.Header>Anexos</Card.Header>
															<Card.Body className=" d-flex flex-row flex-wrap">
																{attachment.map(card =>
																	card.type === 10 ? (
																		<Card
																			className="w-30 m-3"
																			style={{
																				cursor: "pointer",
																			}}
																			onClick={() => handleModalViewImage(card)}
																		>
																			<img className="card-img-top" src={card.link} variant="top" />
																		</Card>
																	) : (
																		<></>
																	)
																)}
															</Card.Body>
														</Card>
													</Card.Body>
												</Card>
											</Col>
										</Row>
										{modalViewContent.status_id === 16 ? (
											<Row>
												<Col md="12 mt-3" className="d-flex flex-wrap w-100">
													<Card className="card-one w-100">
														<Card.Header className="d-flex justify-content-between">
															<span>Finalização</span>

															<span>
																Finalizado em:{" "}
																{fixHistory.map(item => {
																	if (item.status === "Reparo Concluído") {
																		return formatDate(item.dh);
																	}
																})}
															</span>
														</Card.Header>

														<Card.Body>
															<Card>
																<Card.Header>Descrição de Finalização</Card.Header>
																<Card.Body>
																	{fixHistory.map(item => {
																		if (item.status === "Reparo Concluído") {
																			return item.description;
																		}
																	})}
																</Card.Body>
															</Card>
															<Card className="mt-3">
																<Card.Header>Anexos</Card.Header>
																<Card.Body className=" flex-row d-flex flex-wrap">
																	{attachment.map(card =>
																		card.type === 11 ? (
																			<Card
																				className="w-30 m-3"
																				style={{
																					cursor: "pointer",
																				}}
																				onClick={() => handleModalViewImage(card)}
																			>
																				<img className="card-img-top" src={card.link} variant="top" />
																			</Card>
																		) : (
																			<></>
																		)
																	)}
																</Card.Body>
															</Card>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										) : (
											<></>
										)}
									</Modal.Body>
									<Modal.Footer>
										{/* {modalViewContent.status_id === 16 ? (
                      <div></div>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() => handleFinalizacaoView()}
                        className="w-20"
                      >
                        Finalizar Conserto
                      </Button>
                    )} */}
										<Button className="w-20" variant="secondary" onClick={handeCloseOrderView}>
											Fechar
										</Button>
									</Modal.Footer>
								</Modal>

								{/* <Modal
                  size="xl"
                  show={modalAttachment}
                  onHide={handleCloseModalView}
                  onOpened={() => document.body.classList.add("modal-open")}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Visualização</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <Card className="card-one w-100 mb-3">
                      <Card.Img src={selectedAttachment.link}></Card.Img>
                    </Card>
                    <a
                      className="border p-2 my-3"
                      target="_blank"
                      href={selectedAttachment.link}
                    >
                      Visualizar em outra aba
                    </a>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalView}>
                      Fechar
                    </Button>
                  </Modal.Footer>
                </Modal> */}

								<Modal size="xl" show={modalConsertoShow} onHide={handleCloseConsertoModal}>
									<Modal.Header closeButton>
										<Modal.Title>Detalhes do Pedido</Modal.Title>
									</Modal.Header>
									<form onSubmit={ajaxNewOrder}>
										<Modal.Body>
											<Row>
												<Col>
													<Card className="mt-3 mx-0 p-2">
														<span className="d-flex justify-content-between align-items-center">
															<strong className="">Cliente: </strong>
															<Form.Select className="w-80" name="cliente" id="cliente" maxLength="64" required>
																<option>---Selecione---</option>
																{users.map(client => (
																	<option value={client.id} key={client.id}>
																		{client.name}
																	</option>
																))}
															</Form.Select>
														</span>
													</Card>
												</Col>
											</Row>

											<Row>
												<Col>
													<Card className="mt-3 p-2">
														<span className="d-flex justify-content-between align-items-center flex-wrap flex-row">
															<strong>Material: </strong>
															<Form.Select name="material" id="material" className="w-80">
																{metals.map(metal => (
																	<option value={metal.id}>{metal.name}</option>
																))}
															</Form.Select>
														</span>
													</Card>
												</Col>
												<Col>
													<Card className="mt-3 p-2">
														<div className="d-flex justify-content-between align-items-center flex-wrap flex-row">
															<strong>Cor metal: </strong>
															<Form.Select name="cor" id="cor" className="w-80">
																{materialColor.map(color => (
																	<option value={color.id}>{color.name}</option>
																))}
															</Form.Select>
														</div>
													</Card>
												</Col>
											</Row>

											<Row>
												<Col>
													<Card className="mt-3 p-2">
														<span className="d-flex justify-content-between align-items-center flex-wrap flex-row">
															<strong>
																Código da Peça:{" "}
																<span
																	style={{
																		color: "red",
																	}}
																>
																	*
																</span>
															</strong>
															<Form.Control
																type="text"
																name="codicoPeca"
																id="codicoPeca"
																required
																className="w-100"
																placeholder="Digite o código da peça"
															></Form.Control>
														</span>
													</Card>
												</Col>

												<Col>
													<Card className="mt-3 p-2">
														<span className="d-flex justify-content-between align-items-center flex-wrap flex-row">
															<strong>
																Quantidade:{" "}
																<span
																	style={{
																		color: "red",
																	}}
																>
																	*
																</span>
															</strong>
															<Form.Control
																type="number"
																name="amount"
																id="amount"
																step=".01"
																required
																className="w-100"
																placeholder="Digite o peso inicial"
															></Form.Control>
														</span>
													</Card>
												</Col>
											</Row>

											<Row>
												<Col>
													<Card className="mt-3 mb-3 p-2">
														<span>
															<strong>
																Descrição:{" "}
																<span
																	style={{
																		color: "red",
																	}}
																>
																	*
																</span>
															</strong>
															<textarea name="descricao" id="descricao" rows={10} className="form-control"></textarea>
														</span>
													</Card>
												</Col>
											</Row>
											<Accordion alwaysOpen>
												<Accordion.Item eventKey="4">
													<Accordion.Header>Anexos</Accordion.Header>
													<Accordion.Body>
														<MultipleFileUpload onFileChange={handleFileChange} />
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										</Modal.Body>

										<Modal.Footer>
											<Button variant="primary" type="submit">
												Salvar
											</Button>

											<Button variant="secondary" onClick={() => setModalConsertoShow(false)}>
												Fechar
											</Button>
										</Modal.Footer>
									</form>
								</Modal>

								<Modal size="xl" show={modalFinalizacao} onHide={handleCloseModalFinalizacao}>
									<Modal.Header closeButton>
										<Modal.Title>Finalizar</Modal.Title>
									</Modal.Header>
									<form onSubmit={ajaxFinalizacao}>
										<Modal.Body>
											<Row>
												<Col>
													<Card className="mt-3 p-2">
														<span className="d-flex justify-content-between align-items-center flex-wrap flex-row">
															<strong>
																Peso:{" "}
																<span
																	style={{
																		color: "red",
																	}}
																>
																	*
																</span>
															</strong>
															<Form.Control
																type="number"
																name="weight"
																id="weight"
																step=".01"
																required
																className="w-100"
																placeholder="Digite o peso inicial"
															></Form.Control>
														</span>
													</Card>
												</Col>
											</Row>

											<Row>
												<Col>
													<Card className="mt-3 mb-3 p-2">
														<span>
															<strong>
																Descrição:{" "}
																<span
																	style={{
																		color: "red",
																	}}
																>
																	*
																</span>
															</strong>
															<textarea name="descricao" id="descricao" rows={10} className="form-control"></textarea>
														</span>
													</Card>
												</Col>
											</Row>
											<Accordion alwaysOpen>
												<Accordion.Item eventKey="4">
													<Accordion.Header>Anexos</Accordion.Header>
													<Accordion.Body>
														<MultipleFileUpload onFileChange={handleFileChange} />
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										</Modal.Body>

										<Modal.Footer>
											<Button variant="primary" type="submit">
												Salvar
											</Button>

											<Button variant="secondary" onClick={() => handleCloseModalFinalizacao()}>
												Fechar
											</Button>
										</Modal.Footer>
									</form>
								</Modal>

								<Modal size="xl" show={openEditModal} onHide={closeEditModal} onOpened={() => document.body.classList.add("modal-open")}>
									<Modal.Header closeButton>
										<Modal.Title>Histórico de pedido</Modal.Title>
									</Modal.Header>

									<form onSubmit={handleUpdateStatus}>
										<Modal.Body>
											<Card className="card-one d-flex text-center">
												<Card.Title>Status Atual do Pedido: {orderDataToStatus.status}</Card.Title>
											</Card>

											<Card className="card-one d-flex align-items-center justify-content-center text-center w-100">
												<div className="d-flex flex-wrap justify-content-center" style={{ maxWidth: "80%" }}>
													{statusToUpdate.map(status => (
														<Button
															style={{
																width: "250px",
																margin: "5px",
															}}
															value={status.id}
															name="status"
															id="status"
															onClick={e => handleSelectedStatus(e.target.value)}
														>
															{status.name}
														</Button>
													))}
												</div>
											</Card>

											<Card className="mt-3 card-one d-flex text-center">
												<Card.Title>Novo status: {statusSelecionado.name}</Card.Title>
											</Card>

											<Card className="mt-3 card-one d-flex text-center">
												<textarea className="form-control" name="description" id="description" placeholder="Digite a descrição"></textarea>
											</Card>
										</Modal.Body>
										<input type="hidden" id="status_id" name="status_id" value={statusSelecionado.id}></input>
										<Modal.Footer>
											<Button variant="secondary" onClick={handleClose}>
												Fechar
											</Button>
											<Button variant="primary" type="submit">
												Atualizar
											</Button>
										</Modal.Footer>
									</form>
								</Modal>

								<Modal size="xl" show={openModalMetalAtribuicao} onHide={closeModalMetalAtribuicao}>
									<Modal.Header closeButton>
										<Modal.Title>Atrbuir saldo de metal</Modal.Title>
									</Modal.Header>
									<form onSubmit={handleAtribuirMetal}>
										<Modal.Body className="g-3">
											<Card className="d-flex align-items-center justify-content-center p-3 mb-3">
												<h4>Material: {modalMetalAtribuicaoDetal.material_name}</h4>
												<input type="hidden" name="metal" id="metal" value={modalMetalAtribuicaoDetal.metal}></input>
											</Card>
											<Row>
												<Col>
													<Card className="d-flex align-items-center justify-content-center">
														<h4>Quantidade Necessária: {modalMetalAtribuicaoDetal.require_amount}</h4>
													</Card>
												</Col>
												<Col>
													<Card className="d-flex align-items-center justify-content-center">
														<h4>Saldo do Cliente: {modalMetalAtribuicaoDetal.balance_client}</h4>
													</Card>
												</Col>

												<Col md={12}>
													<textarea
														className="mt-3 form-control"
														name="modal_form_metal_input_text"
														id="modal_form_metal_input_text"
														placeholder="Observação"
													></textarea>
												</Col>
											</Row>
										</Modal.Body>

										<Modal.Footer>
											<Button variant="secondary" onClick={closeModalMetalAtribuicao}>
												Fechar
											</Button>
											<Button variant="primary" type="submit">
												Atribuir
											</Button>
										</Modal.Footer>
									</form>
								</Modal>
							</Card.Body>
							<Card.Footer className="d-flex justify-content-center">
								<Pagination className="pagination-space pagination-circled mb-0">
									{Array.from({ length: totalPages }, (_, index) => {
										if (index + 1 === currentPage || index + 1 === 1 || index + 1 === totalPages || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2)) {
											return (
												<Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageClick(index + 1)}>
													{index + 1}
												</Pagination.Item>
											);
										} else if ((index + 1 === currentPage - 3 && currentPage > 5) || (index + 1 === currentPage + 3 && currentPage < totalPages - 4)) {
											return <Pagination.Ellipsis key={index + 1} onClick={() => handlePageClick(index + 1)} />;
										}
										return null;
									})}
								</Pagination>
							</Card.Footer>
						</Card>
					</Col>
				</Row>

				<Footer />
				<ToastContainer />
			</div>
			<Modal size="lg" show={modalAttachmentShow} onHide={handleCloseModalAttachment}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<Card>
						<Card.Body>
							<div className="d-flex justify-content-center">
								<img src={modalAttachment} className="img-fluid" alt="Attachment" />
							</div>
						</Card.Body>
					</Card>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
}
